import React, { useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Colors } from 'constants/Colors';
import { ReactComponent as GooglePlayIcon } from 'images/play-store-button.svg';
import { ReactComponent as AppleIcon } from 'images/app-store-button.svg';
import useIsMobile from 'hooks/useIsMobile';
import { ReactComponent as BuildingIcon } from 'images/topBuilding.svg';
import { ReactComponent as TheIcon } from 'images/topThe.svg';
import { ReactComponent as RevIcon } from 'images/topRev.svg';
import { ReactComponent as BoxIcon } from 'images/boxIcon.svg';
import { ReactComponent as SettingIcon } from 'images/settingIcon.svg';
import { ReactComponent as ShopIcon } from 'images/shopIcon.svg';
import { ReactComponent as TChartIcon } from 'images/tChart.svg';
import { ReactComponent as DolIcon } from 'images/dolIcon.svg';
import { ReactComponent as WalletIcon } from 'images/walletIcon.svg';
import { ReactComponent as SciIcon } from 'images/sciIcon.svg';
import { ReactComponent as ArrowUpRightIcon } from 'images/arrow-up-right.svg';

const Home = () => {
  const isMobile = useIsMobile();

  const appFunctionList = [
    {
      Icon: ShopIcon,
      title: 'Fund & Store',
      lists: ['Convert Fiat to Crypto', 'Non-custodial Wallet (multicoin, multichain)'],
      action: {},
    },
    {
      Icon: TChartIcon,
      title: 'Trade',
      lists: [
        'Multichain Presale Pad',
        'Multichain Charts, Swap & Decentralized Exchange (DEX)',
        'NFT & Ordinals Marketplace',
        'Bridge',
      ],
      action: {},
    },
    {
      Icon: DolIcon,
      title: 'Earn',
      lists: ['Staking Pools', 'Farms', 'Hyper Pools'],
      action: {},
    },
    {
      Icon: WalletIcon,
      title: 'Cashout & Spend',
      lists: ['Crypto Cards'],
      action: {},
    },
  ];

  const paymentList = [
    {
      src: '/images/integration.png',
      title: 'Seamless Integration',
      desc: 'Online APIs, Mobile Apps, In-store Terminals',
    },
    {
      src: '/images/change.png',
      title: 'Zero Crypto Handling',
      desc: 'Receive payments in your currency',
    },
    {
      src: '/images/fraud.png',
      title: 'Fraud-Free',
      desc: 'No chargebacks with irreversible crypto payments',
    },
    {
      src: '/images/wallet.png',
      title: 'Universal Wallet Compatibility',
      desc: 'Supports 100+ wallets',
    },
    {
      src: '/images/piggy-bank.png',
      title: 'Cost-Effective',
      desc: 'Fees lower than credit card transactions',
    },
    {
      src: '/images/money.png',
      title: 'Wide Coin Support',
      desc: 'Over 20 cryptocurrencies including Bitcoin and altcoins',
    },
    {
      src: '/images/network.png',
      title: 'Expansive Reach',
      desc: 'Connect with millions across any channel',
    },
    {
      src: '/images/internet.png',
      title: 'Worldwide Access',
      desc: 'Operate in 229 countries and territories instantly',
    },
  ];

  return (
    <HomePage>
      <FlexCenter id="home">
        <SectionWrapper1 style={{ position: 'relative', paddingBottom: '120px' }}>
          <BuildingIcon style={{ maxWidth: '80vw' }} />
          <TheIcon style={{ marginTop: '-6px', maxWidth: '80vw' }} />
          <RevIcon style={{ marginTop: '-6px', marginLeft: '28px', maxWidth: '80vw' }} />
          <Text
            size={24}
            bold="400"
            color={Colors.textGray1}
            style={{ lineHeight: '40px', paddingTop: '20px', margin: 'auto' }}
          >
            Decentralized applications to full blockchains - we build it all!
          </Text>
          <FlexCenterCard style={{ marginTop: '64px' }}>
            <Section1Card>
              <CardIconWrapper>
                <BoxIcon />
              </CardIconWrapper>
              <div style={{ marginTop: '36px' }} />
              <Text
                bold="600"
                size={16}
                color={Colors.black}
                style={{ padding: '8px', borderRadius: '4px', background: Colors.cardBack }}
              >
                Products
              </Text>
              <CardListItem href='https://thesphynx.co' target='_blank' rel='noopener noreferrer'>
                All-in-One DeFi dApp
              </CardListItem>
              <CardListItem href='https://play.google.com/store/apps/details?id=com.sphynx' target='_blank' rel='noopener noreferrer'>
                All-in-One DeFi Mobile dApp
              </CardListItem>
              <CardListItem href='https://sphynxpay.co' target='_blank' rel='noopener noreferrer'>
                SPay Crypto Payment Processing
              </CardListItem>
              <CardListItem href='https://thesphynx.co/cards' target='_blank' rel='noopener noreferrer'>
                Visa Crypto Cards
              </CardListItem>
            </Section1Card>
            <Section1Card>
              <CardIconWrapper>
                <SettingIcon />
              </CardIconWrapper>
              <div style={{ marginTop: '36px' }} />
              <Text
                bold="600"
                size={16}
                color={Colors.black}
                style={{ padding: '8px', borderRadius: '4px', background: Colors.cardBack }}
              >
                Services
              </Text>
              <CardListItem href='https://t.me/zoro2607' target='_blank' rel='noopener noreferrer'>
                Blockchain Software Development
              </CardListItem>
              <CardListItem href='https://thesphynx.co/launchpad/bsc' target='_blank' rel='noopener noreferrer'>
                Token Launch and Marketing Services
              </CardListItem>
            </Section1Card>
          </FlexCenterCard>
        </SectionWrapper1>
      </FlexCenter>
      <FlexCenter id="all-in-one-defi">
        <Section5 style={{ maxWidth: '1300px', padding: '24px' }}>
          <FlexBetween>
            <FlexColumn style={{ flex: 1 }}>
              <Text size={60} color={Colors.black} bold="700">
                ALL-IN-ONE <span style={{ color: Colors.purple }}>DeFi dAPP</span> & MOBILE
              </Text>
              <Text size={18} color={Colors.textGray} bold="400">
                SphynxFi offers comprehensive DeFi utilities for every phase of your crypto journey, from the initial
                onramp to the final offramp. Our all-in-one platform truly encompasses everything you need:
              </Text>
              <Button onClick={() => window.open('https://thesphynx.co')}>
                <FlexCenter>
                  <span>Enter dAPP</span> <ArrowUpRightIcon />
                </FlexCenter>
              </Button>
              <FlexBetween style={{ marginTop: '40px' }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sphynx"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <GooglePlayIcon width={182} height={54} />
                </a>
                <a
                  href="https://apps.apple.com/nz/app/sphynx-defi-app/id1626361848"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <AppleIcon width={182} height={54} />
                </a>
              </FlexBetween>
            </FlexColumn>
            <FlexColumn
              style={{
                flex: 1,
                position: 'relative',
                overflow: 'hidden',
                alignItems: 'center',
                minWidth: '300px',
                flexDirection: 'row',
              }}
            >
              <img
                src={'/images/papp.png'}
                style={{ borderRadius: '12px', zIndex: 1, maxWidth: '90vw' }}
                alt="phone-background"
              />
              {!isMobile && (
                <img
                  src={'/images/dapp.png'}
                  style={{ marginLeft: '-150px', maxWidth: '90vw' }}
                  alt="phone-background"
                />
              )}
            </FlexColumn>
          </FlexBetween>
          <FlexCenterCard style={{ marginTop: '32px' }}>
            {appFunctionList.map(({ title, lists, Icon, action }, index) => (
              <Section2Card key={index}>
                <IconWrapper>
                  <Icon color="#BA85FF" width={28} height={28} />
                </IconWrapper>
                <Text size={16} bold="700" color={Colors.black} style={{ paddingTop: '16px' }}>
                  {title}
                </Text>
                <ul>
                  {lists.map((desc, nIndex) => (
                    <li key={`${index}-${nIndex}`}>
                      <Text size={12} bold="400" color={Colors.black} style={{ lineHeight: '27px' }}>
                        {desc}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Section2Card>
            ))}
          </FlexCenterCard>
          <FlexCenter style={{ marginTop: '24px' }}>
            <Button onClick={() => window.open('https://thesphynx.co')}>
              <FlexCenter>
                <span>Enter the dAPP</span> <ArrowUpRightIcon />
              </FlexCenter>
            </Button>
          </FlexCenter>
        </Section5>
      </FlexCenter>
      <FlexCenter id="visa-crypto">
        <Section5 style={{ maxWidth: '1300px', padding: '24px', marginTop: '140px' }}>
          <FlexBetween>
            <FlexColumn
              style={{
                position: 'relative',
                overflow: 'hidden',
                alignItems: 'center',
                minWidth: '300px',
                flexDirection: 'row',
              }}
            >
              <img
                src={'/images/visa-back.png'}
                style={{ borderRadius: '12px', zIndex: 1, maxWidth: '85vw' }}
                alt="phone-background"
              />
            </FlexColumn>
            <FlexColumn style={{ flex: 1 }}>
              <Text size={60} color={Colors.black} bold="800">
                VISA <span style={{ color: Colors.purple }}>CRYPTO</span> CARDS
              </Text>
              <Text size={16} color={Colors.textGray} bold="400">
                Easily spend your cryptocurrency in the real world with our Visa Crypto Cards, tailored for both digital
                and physical use. Opt for our Virtual Cards for instant, hassle-free shopping both online and in
                physical stores, compatible with Apple Pay, Google Pay, and Samsung Pay - no KYC or ID needed
              </Text>
              <Text size={16} color={Colors.textGray} bold="400">
                For greater spending power, choose our Physical Cards, offering higher limits with KYC verification.
              </Text>
              <Text size={16} color={Colors.textGray} bold="400">
                Versatile funding options include Bitcoin, major altcoins, and stablecoins, making your crypto as
                spendable as cash.
              </Text>
              <Button onClick={() => window.open('https://thesphynx.co/cards')} style={{ marginTop: '32px' }}>
                <FlexCenter>
                  <span>Get Your Crypto Card</span> <ArrowUpRightIcon />
                </FlexCenter>
              </Button>
            </FlexColumn>
          </FlexBetween>
        </Section5>
      </FlexCenter>
      <FlexCenter
        id="spay"
        style={{
          background: 'url(images/payment-back.png) no-repeat, url(images/payment-back1.png) no-repeat bottom',
          paddingBottom: '32px',
        }}
      >
        <Section5 style={{ maxWidth: '1300px', padding: '24px', marginTop: '140px' }}>
          <FlexCenter style={{ flexDirection: 'column' }}>
            <Text size={60} color={Colors.black} bold="800">
              ACCEPT <span style={{ color: Colors.purple }}>CRYPTO PAYMENTS</span> GLOBALLY
            </Text>
            <Text size={24} color={Colors.black} bold="600">
              Accept Global Crypto Payments with SPay
            </Text>
            <BlackButton onClick={() => window.open('https://t.me/zoro2607')}>Book Demo</BlackButton>
            <FlexCenter style={{ flexWrap: 'wrap', textAlign: 'center', alignItems: 'stretch' }}>
              {paymentList.map(({ src, title, desc }) => (
                <Section1Card key={title}>
                  <BlackCardIconWrapper>
                    <img src={src} alt="p-icon" style={{ width: '32px', height: '32px' }} />
                  </BlackCardIconWrapper>
                  <FlexCenter style={{ flexDirection: 'column', marginTop: '36px', marginBottom: '48px' }}>
                    <Text size={24} color={Colors.black} bold="600" style={{ textAlign: 'center' }}>
                      {title}
                    </Text>
                    <Text size={16} color={Colors.black} bold="400" style={{ maxWidth: '250px', textAlign: 'center' }}>
                      {desc}
                    </Text>
                  </FlexCenter>
                </Section1Card>
              ))}
            </FlexCenter>
          </FlexCenter>
        </Section5>
      </FlexCenter>
      <FlexCenter id="blockchain-service">
        <Section5 style={{ maxWidth: '1300px', padding: '24px' }}>
          <FlexBetween style={{ alignItems: 'center' }}>
            <FlexColumn
              style={{
                flex: 3,
                position: 'relative',
                overflow: 'hidden',
                alignItems: 'center',
                minWidth: '300px',
                flexDirection: 'row',
              }}
            >
              <SciIcon />
            </FlexColumn>
            <FlexColumn style={{ flex: 4 }}>
              <Text size={60} color={Colors.black} bold="800">
                Blockchain <br /> <span style={{ color: Colors.purple }}>Development</span> Service
              </Text>
              <Text size={16} color={Colors.textGray} bold="400">
                Discover the power of bespoke blockchain solutions with our team. From crafting decentralized
                applications to comprehensive blockchain integrations, our expertise is evident in our flagship product,
                supporting 16+ blockchains (EVM and non-EVM). It's not just a service; it's a demonstration of our
                complex, cutting-edge capabilities in the all-encompassing world of DeFi.
              </Text>
              <BlackButton onClick={() => window.open('https://t.me/zoro2607')} style={{ marginTop: '32px' }}>
                <FlexCenter>
                  <span>Discuss Your Project</span>
                </FlexCenter>
              </BlackButton>
            </FlexColumn>
          </FlexBetween>
        </Section5>
      </FlexCenter>
      {/* <FlexCenter id="contact-us" style={{ background: Colors.bgGray, padding: '8px' }}>
        <Section5
          style={{
            maxWidth: '1300px',
            width: '90vw',
            padding: '8px',
            background: Colors.white,
            borderRadius: '12px',
            marginBottom: '40px',
          }}
        >
          <FlexStart style={{ alignItems: 'center', padding: '24px' }}>
            <FlexColumn style={{ flex: 1 }}>
              <Text size={40} color={Colors.black} bold="600">
                Get in touch
              </Text>
              <Text size={18} color={Colors.textGray} bold="400">
                Our friendly team would love to hear from you.
              </Text>
              <FlexStart
                style={{
                  flexDirection: 'column',
                  gap: '12px',
                  marginTop: '24px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Text size={14} color={Colors.black} bold="400">
                    Your Name
                  </Text>
                  <input placeholder="Enter Your Name" />
                </div>
                <div style={{ width: '100%' }}>
                  <Text size={14} color={Colors.black} bold="400">
                    Email
                  </Text>
                  <input placeholder="emailaddress@gmail.com" />
                </div>
                <div style={{ width: '100%' }}>
                  <Text size={14} color={Colors.black} bold="400">
                    Phone number
                  </Text>
                  <PhoneInput placeholder="+1 (555) 000-0000" value={value} onChange={e => setValue(e)} />
                </div>
                <div style={{ width: '100%' }}>
                  <Text size={14} color={Colors.black} bold="400">
                    Message
                  </Text>
                  <textarea rows={4} placeholder="Enter Your Name" />
                </div>
                <PurpleButton onClick={() => history.push('/app')} style={{ marginTop: '32px' }}>
                  <FlexCenter>
                    <span>Submit</span>
                  </FlexCenter>
                </PurpleButton>
              </FlexStart>
            </FlexColumn>
          </FlexStart>
        </Section5>
      </FlexCenter> */}
    </HomePage>
  );
};

export default Home;

const HomePage = styled.div`
  width: 100%;
  min-height: calc(100vh - 354px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
  background: url(images/topback.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
  object-position: center;

  & svg {
    max-width: 85vw;
  }

  & input,
  textarea {
    border: 1px solid ${Colors.textGray};
    border-radius: 4px;
    padding: 6px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 1080px) {
    background: transparent;
  }
`;
const SectionWrapper1 = styled.div`
  text-align: center;
  max-width: 800px;
  padding: 121px 20px 20px 20px;
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size > 60
        ? `${(size / 3) * 2}px`
        : size > 50
        ? `${(size / 5) * 4}px`
        : size > 40
        ? `${(size / 7) * 6}px`
        : size > 30
        ? `${(size / 8) * 7}px`
        : size > 20
        ? `${(size / 9) * 8}px`
        : `${size}px`};
  }
`;
const FlexCenter = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const FlexCenterCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 24px;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;

  @media screen and (max-width: 1080px) {
    align-items: center;
    justify-content: center;
  }
`;
const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Section5 = styled.div`
  position: relative;
  margin-top: 40px;
  padding: 40px 0px;
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
const Section1Card = styled.div`
  max-width: 290px;
  min-width: 290px;
  z-index: 1;
  background: ${Colors.white};
  border: 1px solid #6666;
  padding: 32px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border-radius: 16px;
  gap: 16px;
`;
const CardListItem = styled.a`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.purple};
  padding: 8px;
  border-radius: 4px;
  background: ${Colors.backPurple};
  box-shadow: 0 0 4px ${Colors.purple};
`
const CardIconWrapper = styled.div`
  position: absolute;
  left: 40px;
  top: 0px;
  background: ${Colors.black};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px;
`;
const BlackCardIconWrapper = styled.div`
  position: absolute;
  left: calc(50% - 24px);
  top: 0px;
  background: ${Colors.black};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px;
`;
const Section2Card = styled.div`
  max-width: 290px;
  min-width: 290px;
  z-index: 1;
  background: ${Colors.white};
  border: 1px solid #6666;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border-radius: 6px;
  gap: 16px;
`;
const IconWrapper = styled.div`
  padding: 9px;
  background: ${Colors.lightDark2};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  background: ${Colors.white};
  border-radius: 6px;
  font-weight: 800;
  font-size: 18px;
  padding: 20px 21px;
  color: ${Colors.black};
  width: fit-content;
  cursor: pointer;
  border: 1px solid #7000ff;
  box-shadow: #895ff2 0px 0px 12px;

  &:hover {
    opacity: 0.8;
  }
`;

const BlackButton = styled.div`
  background: ${Colors.black};
  text-align: center;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  padding: 12px 36px;
  color: ${Colors.white};
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const PurpleButton = styled.div`
  background: ${Colors.purple};
  width: 100%;
  text-align: center;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  padding: 12px 36px;
  color: ${Colors.white};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
